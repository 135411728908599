import { ButtonBase, Tab, Tabs, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { useEffect, useState } from 'react'
import { localizePath } from '../../../utils/localizePath'
import ContentContainer from '../components/ContentContainer'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import LayoutContainer from '../containers/layout'
import baseTheme from '../styles/theme'

export const query = graphql`
  query ActivitiesPageQuery($language: String) {
    localizedSiteSettings: sanitySiteSettings(language: { eq: $language }) {
      id
      title
      language
      description
      aboutThisProject
      activityCorner
      aResourceSiteBy
    }
    localizedSections: allSanitySection(
      filter: { slug: { current: { ne: null } }, language: { eq: $language } }
    ) {
      edges {
        node {
          id
          _id
          language
          title
          slug {
            current
          }
        }
      }
    }

    activities: allSanityActivity(
      filter: { slug: { current: { ne: null } }, language: { eq: $language } }
    ) {
      edges {
        node {
          id
          _id
          title
          activityCategory
          slug {
            current
          }
          mainImage {
            caption
            colorImage {
              asset {
                url
                fluid(maxWidth: 800) {
                  ...GatsbySanityImageFluid
                }
              }
            }
            outlineImage {
              asset {
                url
                fluid(maxWidth: 800) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  tabs: {
    marginLeft: 25,
    marginRight: 25,
    marginBottom: theme.spacing(6),
    [theme.breakpoints.only('sm')]: {
      marginLeft: 64,
      marginRight: 64
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: 28
    }
  },
  tab: {
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      marginRight: theme.spacing(3)
    }
  },
  resultsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(255px, 1fr))',
    gridTemplateRows: 'minmax(min-content, max-content)',
    margin: '0 -1px',
    height: '100%',
    '&::before': {
      content: '""',
      display: 'block',
      width: '100%',
      position: 'absolute',
      borderTop: `1px solid ${baseTheme.custom.colors.black}`
    },
    overflowY: 'scroll',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(188px, 1fr))'
    }
  },
  resultBlock: {
    textAlign: 'center',
    border: '1px solid black',
    padding: `20px 20px 68px 20px`,
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 48
    },
    margin: '0 -1px -1px 0',
    display: 'block',
    '& .hover-img': {
      opacity: 0
    },
    '@media (hover:none)': {
      '& .hover-img': {
        opacity: 1
      }
    },
    '@media (hover:hover)': {
      '&:hover .hover-img': {
        opacity: 1
      }
    }
  },
  blockImageContainer: {
    position: 'relative',
    width: '100%',
    height: 0,
    paddingBottom: '100%',
    marginBottom: 25
  },
  blockImage: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    transition: 'all .4s ease'
  }
}))

const ActivitiesPage = props => {
  const { data, errors } = props
  const [tabsValue, setTabsValue] = useState('All')
  const classes = useStyles()
  const [isClient, setIsClient] = useState(false)
  const { localizedSiteSettings, localizedSections, activities } = data
  const { language } = localizedSiteSettings

  if (errors) {
    return (
      <LayoutContainer>
        <GraphQLErrorList errors={errors} />
      </LayoutContainer>
    )
  }
  const key = isClient ? 'clientRender' : 'serverRender'

  useEffect(() => {
    setIsClient(true)
  }, [])

  const handleTabsChange = (event, newValue) => {
    setTabsValue(newValue)
  }
  const items = activities.edges

  const filteredResults = items
    .filter(item => {
      switch (tabsValue) {
        case 'Tips':
        case 'Tools':
        case 'Lessons':
          return item.node.activityCategory === tabsValue
        default:
          return true
      }
    })
    .sort((a, b) => a.node.title.localeCompare(b.node.title))

  const render = () => {
    return (
      <LayoutContainer
        key={key}
        language={language}
        sections={localizedSections}
        siteSettings={localizedSiteSettings}
      >
        <SEO
          title={localizedSiteSettings.title}
          description={localizedSiteSettings.description}
          keywords={localizedSiteSettings.keywords}
        />
        <ContentContainer className={classes.root}>
          <Tabs value={tabsValue} onChange={handleTabsChange} className={classes.tabs}>
            <Tab value="All" label="All Activities" className={classes.tab} />
            <Tab value="Tips" label="Tips" className={classes.tab} />
            <Tab value="Tools" label="Tools" className={classes.tab} />
            <Tab value="Lessons" label="Lessons" className={classes.tab} />
          </Tabs>
          <div className={classes.resultsContainer}>
            {filteredResults.map(activity => (
              <ButtonBase
                key={activity.node._id}
                className={classes.resultBlock}
                href={localizePath({
                  path: `/activities/${activity.node.slug?.current}`,
                  languageId: language
                })}
                target="_blank"
              >
                <div className={classes.blockImageContainer}>
                  {activity.node.mainImage && (
                    <>
                      {activity.node.mainImage.outlineImage && (
                        <Img
                          fluid={activity.node.mainImage.outlineImage.asset.fluid}
                          className={clsx(classes.blockImage, 'default-img')}
                          style={{
                            position: 'absolute'
                          }}
                        />
                      )}
                      {activity.node.mainImage.colorImage && (
                        <Img
                          fluid={activity.node.mainImage.colorImage.asset.fluid}
                          className={clsx(classes.blockImage, 'hover-img')}
                          style={{
                            position: 'absolute'
                          }}
                        />
                      )}
                    </>
                  )}
                </div>
                <Typography variant="h2">{activity.node.title}</Typography>
              </ButtonBase>
            ))}
          </div>
        </ContentContainer>
      </LayoutContainer>
    )
  }

  return render()
}

export default ActivitiesPage
